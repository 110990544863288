import { useState } from 'react';
import { File, Image } from 'lucide-react';
import { Menu, Transition } from '@headlessui/react';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { cookie, redirectApps, prefix } from '~/utils/';

import NavDropdown from './NavDropdown';
import NavLink from './NavLink';
import { subscriptionType, usePricingPopup } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';

const AdvanceTools = () => {
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const sState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state);

  const myAccountLink = () => {
    if (cookie.getValue('rdct') && cookie.getValue('rdct') in redirectApps) {
      return `https://${prefix}ai-pro.org/pricing-redirect?from=chat`;
    }
    return `https://${prefix}start.ai-pro.org/my-account`;
  };

  const handlePricing = () => {
    if (!proState.isPro || !proState.isProMax) {
      
      if ((cookie.getValue('flow') === null || cookie.getValue('flow') === undefined || cookie.getValue('flow') === "") && (cookie.getValue('kt8typtb') != "arcana_wp")) {
        showPricing(() => {
          sState.setShowPricingPopup(true);
        });
      } else {
        window.location.href = `https://${prefix}ai-pro.org/pricing-redirect?from=chatapp`;
      }

    }
  };

  return (
    <>
      <Menu>
        <NavDropdown
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          svg={<AiOutlineAppstoreAdd size={18} style={{ transform: 'rotate(-90deg)' }} />}
          text="Advanced Tools"
        >
          <Transition
            as="div"
            show={dropdownOpen}
            appear={true}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 translate-y-10"
            enterTo="transform opacity-100 translate-y-0"
          >
            <Menu.Item as="div">
              <NavLink
                className="nav-menu-link flex w-full cursor-pointer items-center rounded-lg px-3 py-2 text-sm transition-colors duration-200"
                aria-label="ChatPDF"
                svg={() => (
                  <div className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-red-800">
                    <File size={16} role="img" aria-label="ChatPDF" className="text-white" />
                  </div>
                )}
                text="ChatPDF"
                description="PDF Q&A"
                status="pro"
                onClick={handlePricing}
              />
            </Menu.Item>
            <Menu.Item as="div">
              <NavLink
                className="nav-menu-link flex w-full cursor-pointer items-center rounded-lg px-3 py-2 text-sm text-white transition-colors duration-200"
                aria-label="DreamPhoto"
                svg={() => (
                  <div className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-green-800">
                    <Image size={16} role="img" aria-label="DreamPhoto" className="text-white" />
                  </div>
                )}
                text="DreamPhoto"
                description="AI art creation"
                status="pro"
                onClick={handlePricing}
              />
            </Menu.Item>
            <Menu.Item as="div">
              <NavLink
                className="nav-menu-link mt-3 flex w-full cursor-pointer items-center rounded-lg px-3 py-3 text-sm text-white transition-colors duration-200"
                aria-label="See All 19 Tools"
                svg={() => (
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M0 2.16022C0 0.967164 0.941165 0 2.10215 0H5.84946C7.01045 0 7.95161 0.967164 7.95161 2.16022V2.9116C7.95161 4.10466 7.01045 5.07182 5.84946 5.07182H2.10215C0.941165 5.07182 0 4.10466 0 2.9116V2.16022Z"
                      fill="#3753FF"
                    />
                    <path
                      d="M9.04839 14.0884C9.04839 12.8953 9.98955 11.9282 11.1505 11.9282H14.8978C16.0588 11.9282 17 12.8953 17 14.0884V14.8398C17 16.0328 16.0588 17 14.8978 17H11.1505C9.98955 17 9.04839 16.0328 9.04839 14.8398V14.0884Z"
                      fill="#99C8FF"
                    />
                    <path
                      d="M9.04839 2.62983C9.04839 1.17742 10.1942 0 11.6075 0H14.4409C15.8542 0 17 1.17742 17 2.62983V8.07735C17 9.52977 15.8542 10.7072 14.4409 10.7072H11.6075C10.1942 10.7072 9.04839 9.52977 9.04839 8.07735V2.62983Z"
                      fill="#99C8FF"
                    />
                    <path
                      d="M0 8.92265C0 7.47024 1.14577 6.29282 2.55914 6.29282H5.39247C6.80585 6.29282 7.95161 7.47024 7.95161 8.92265V14.3702C7.95161 15.8226 6.80585 17 5.39247 17H2.55914C1.14577 17 0 15.8226 0 14.3702V8.92265Z"
                      fill="#99C8FF"
                    />
                  </svg>
                )}
                heading={
                  <h3 className="text-xs">
                    See all <span className="font-semibold">AI-PRO</span> Tools
                  </h3>
                }
                onClick={handlePricing}
                target="_blank"
              />
            </Menu.Item>
          </Transition>
        </NavDropdown>
      </Menu>
    </>
  );
};

export default AdvanceTools;
