export const flags = [
    'keyword',
    'adid',
    'regRedirectWP',
    'ppg',
    'flow',
    'pmt',
    'kt8typtb',
    'locales',
    'pmt',
    'p_toggle',
    'price_click',
    'pp_ctaclr',
    'f_sle',
    'splash',
    'verify',
    'rdct',
    'mode',
]

const setCookie = (key, value) => {
    document.cookie = key + '=' + encodeURIComponent(value) + '; domain=.ai-pro.org; path=/';
};
  
const setFlagFromURL = (flagName) => {
    const flagValue = new URLSearchParams(window.location.search).get(flagName);
    if (flagValue !== null && flagValue !== '') {
        setCookie(flagName, flagValue);
    }
};

flags.forEach((flag) => {
    setFlagFromURL(flag);
});

